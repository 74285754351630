<template>
  <div>
    <b-modal
      v-model="showModal"
      ok-only
      ok-title="Accept"
      centered
      size="xl"
      :hide-footer="true"
      :title="categorie.title"
      lazy
      static
    >
      <div v-if="categoryInformations && categoryInformations.lg">
        <b-row class="align-items-center mb-1">
          <b-col md="2">
            <a class="mb-2" :href="`${categorie.country === 'UK' ? `https://uk-site.d15pa95o7ce9en.amplifyapp.com/uk/product-category/${categoryInformations.lg.slug}` : `https://kipli.com/${categorie.country.toLowerCase()}/product-category/${categoryInformations.lg.slug}`}`" target="_blank">
              <b-button
                variant="primary"
                class="btn-cart"
              >
                <span>Page catégorie</span>
              </b-button>
            </a>
          </b-col>
        </b-row>
        <quill-editor-snow style="display: none;" />
        <app-collapse>
          <app-collapse-item title="Titre de catégorie">
            <p>Titre:</p>
            <b-form-input
              v-model="categoryInformations.lg.title"
              class="mb-2"
            />
          </app-collapse-item>
          <app-collapse-item title="Réassurance">
            <b-row
              class="my-2"
            >
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div v-if="categoryInformations.lg.reinsurance">
                  <p>Titre:</p>
                  <b-form-input
                    v-if="categoryInformations.lg.reinsurance"
                    v-model="categoryInformations.lg.reinsurance.title"
                    class="mb-2"
                  />
                  <p>Sous-titre:</p>
                  <quill-editor
                    v-if="categoryInformations.lg.reinsurance"
                    v-model="categoryInformations.lg.reinsurance.subtitle"
                    :options="snowOption"
                  />
                  <p>Icones:</p>
                  <b-row
                    v-for="(value, keyR) of categoryInformations.lg.reinsurance.values"
                    :key="keyR"
                  >
                    <b-col
                      cols="3"
                      class="primary my-4"
                    >
                      <p>Titre:</p>
                      <b-form-input
                        v-if="value"
                        v-model="value.title"
                        class="mr-4"
                        style="width: 100%"
                      />
                    </b-col>
                    <b-col
                      cols="2"
                      class="primary my-4"
                    >
                      <p>Icone:</p>
                      <b-form-input
                        v-if="value"
                        v-model="value.icon"
                        class="mr-4"
                        style="width: 100%"
                      />
                      <!--<b-img
                        fluid
                        :src="require(`@/assets/images/front/${value.icon}.svg`)"
                      />!-->
                    </b-col>
                    <b-col
                      cols="6"
                      class="primary my-4"
                    >
                      <p>Texte:</p>
                      <quill-editor
                        v-if="value"
                        v-model="value.description"
                        :options="snowOption"
                        style="width: 100%"
                      />
                    </b-col>
                    <b-col
                      cols="1"
                      class="primary my-4"
                    >
                      <b-button
                        variant="primary"
                        class="btn-icon"
                        @click="categoryInformations.lg.reinsurance.values.splice(keyR, 1);"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-col
                    span="12"
                    class="primary d-flex justify-content-end"
                  >
                    <b-button
                      variant="primary"
                      @click="() => categoryInformations.lg.reinsurance.values.push({title: '', icon: '', description: ''})"
                    >
                      <span class="text-nowrap">Ajouter une réassurance</span>
                    </b-button>
                  </b-col>
                </div>
              </b-col>
              <b-col
                cols="6"
                class="primary "
              >
                <b-img
                  fluid
                  :src="require(`@/assets/images/editor/reinsurance.png`)"
                />
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Services">
            <b-row
              class="my-2"
            >
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div v-if="categoryInformations.lg.services">
                  <p>Titre:</p>
                  <b-form-input
                    v-if="categoryInformations.lg.services"
                    v-model="categoryInformations.lg.services.title"
                    class="mb-2"
                  />
                  <p>Sous-titre:</p>
                  <quill-editor
                    v-if="categoryInformations.lg.services"
                    v-model="categoryInformations.lg.services.subtitle"
                    :options="snowOption"
                  />
                  <p>Icones:</p>
                  <b-row
                    v-for="(value, keyR) of categoryInformations.lg.services.values"
                    :key="keyR"
                  >
                    <b-col
                      cols="3"
                      class="primary my-4"
                    >
                      <p>Titre:</p>
                      <b-form-input
                        v-if="value"
                        v-model="value.title"
                        class="mr-4"
                        style="width: 100%"
                      />
                    </b-col>
                    <b-col
                      cols="2"
                      class="primary my-4"
                    >
                      <p>Icone:</p>
                      <b-form-input
                        v-if="value"
                        v-model="value.icon"
                        class="mr-4"
                        style="width: 100%"
                      />
                      <!--<b-img
                        fluid
                        :src="require(`@/assets/images/front/${value.icon}.svg`)"
                      />!-->
                    </b-col>
                    <b-col
                      cols="1"
                      class="primary my-4"
                    >
                      <b-button
                        variant="primary"
                        class="btn-icon"
                        @click="categoryInformations.lg.services.values.splice(keyR, 1);"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-col
                    span="12"
                    class="primary d-flex justify-content-end"
                  >
                    <b-button
                      variant="primary"
                      @click="() => categoryInformations.lg.services.values.push({title: '', icon: ''})"
                    >
                      <span class="text-nowrap">Ajouter un service</span>
                    </b-button>
                  </b-col>
                </div>
              </b-col>
              <b-col
                cols="6"
                class="primary "
              >
                <b-img
                  fluid
                  :src="require(`@/assets/images/editor/services.png`)"
                />
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Texte/Image (Univers)">
            <b-row
              class="my-2"
            >
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div v-if="categoryInformations.lg.univers">
                  <p>Sur-titre:</p>
                  <b-form-input
                    v-if="categoryInformations.lg.univers"
                    v-model="categoryInformations.lg.univers.subTitle"
                    class="mb-2"
                  />
                  <p>Titre:</p>
                  <b-form-input
                    v-if="categoryInformations.lg.univers"
                    v-model="categoryInformations.lg.univers.title"
                    class="mb-2"
                  />
                  <p>Description:</p>
                  <quill-editor
                    v-if="categoryInformations.lg.univers"
                    v-model="categoryInformations.lg.univers.description"
                    :options="snowOption"
                  />
                  <p>Image:</p>
                  <b-form-input
                    v-if="categoryInformations.lg.univers"
                    v-model="categoryInformations.lg.univers.image"
                    class="mb-2"
                  />
                </div>
              </b-col>
              <b-col
                cols="6"
                class="primary "
              >
                <b-img
                  fluid
                  :src="require(`@/assets/images/editor/services.png`)"
                />
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Certifications">
            <b-row
              class="my-2"
            >
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div v-if="categoryInformations.lg.certifications">
                  <p>Titre:</p>
                  <b-form-input
                    v-if="categoryInformations.lg.certifications"
                    v-model="categoryInformations.lg.certifications.title"
                    class="mb-2"
                  />
                  <p>Icones:</p>
                  <b-row
                    v-for="(value, keyR) of categoryInformations.lg.certifications.values"
                    :key="keyR"
                  >
                    <b-col
                      cols="3"
                      class="primary my-4"
                    >
                      <p>Titre:</p>
                      <b-form-input
                        v-if="value"
                        v-model="value.title"
                        class="mr-4"
                        style="width: 100%"
                      />
                    </b-col>
                    <b-col
                      cols="2"
                      class="primary my-4"
                    >
                      <p>Icone:</p>
                      <b-form-input
                        v-if="value"
                        v-model="value.icon"
                        class="mr-4"
                        style="width: 100%"
                      />
                    </b-col>
                    <b-col
                      cols="4"
                      class="primary my-4"
                    >
                      <p>Description:</p>
                      <b-form-input
                        v-if="value"
                        v-model="value.description"
                        class="mr-4"
                        style="width: 100%"
                      />
                    </b-col>
                    <b-col
                      cols="1"
                      class="primary my-4"
                    >
                      <b-button
                        variant="primary"
                        class="btn-icon"
                        @click="categoryInformations.lg.certifications.values.splice(keyR, 1);"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-col
                    span="12"
                    class="primary d-flex justify-content-end"
                  >
                    <b-button
                      variant="primary"
                      @click="() => categoryInformations.lg.certifications.values.push({title: '', icon: ''})"
                    >
                      <span class="text-nowrap">Ajouter une certification</span>
                    </b-button>
                  </b-col>
                </div>
              </b-col>
              <b-col
                cols="6"
                class="primary "
              >
                <b-img
                  fluid
                  :src="require(`@/assets/images/editor/certification.png`)"
                />
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Histoire">
            <b-row
              class="my-2"
            >
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div v-if="categoryInformations.lg.explication">
                  <p>Titre:</p>
                  <b-form-input
                    v-model="categoryInformations.lg.explication.title"
                    class="mb-2"
                  />
                  <p>Description:</p>
                  <quill-editor
                    v-model="categoryInformations.lg.explication.description"
                    class="mb-2"
                    :options="snowOption"
                  />
                  <p>Lien (ex: /fr/fabrication/):</p>
                  <b-form-input
                    v-if="categoryInformations.lg.explication.action"
                    v-model="categoryInformations.lg.explication.action.link"
                    class="mb-2"
                  />
                  <p>Nom du lien:</p>
                  <b-form-input
                    v-if="categoryInformations.lg.explication.action"
                    v-model="categoryInformations.lg.explication.action.text"
                    class="mb-2"
                  />
                  <p>Image:</p>
                  <b-form-input
                    v-model="categoryInformations.lg.explication.imageSrc"
                    class="mb-2"
                  />
                </div>
              </b-col>
              <b-col
                cols="6"
                class="primary "
              >
                <b-img
                  fluid
                  :src="require(`@/assets/images/editor/histoire.png`)"
                />
              </b-col>
            </b-row>
          </app-collapse-item>

          <app-collapse-item title="Questions/Réponses">
            <b-row
              class="my-2"
            >
              <b-col
                cols="12"
                class="primary d-flex"
              >
                <div>
                  <p>Questions:</p>
                  <b-row
                    v-for="(value, keyBloc) of categoryInformations.lg.questions"
                    :key="`${keyBloc}-question`"
                  >
                    <b-col
                      cols="5"
                      class="primary my-4"
                    >
                      <p>Question:</p>
                      <b-form-input
                        v-if="value"
                        v-model="value.question"
                        class="mr-4"
                        style="width: 100%"
                      />
                    </b-col>
                    <b-col
                      cols="6"
                      class="primary my-4"
                    >
                      <p>Réponse:</p>
                      <quill-editor
                        v-if="value"
                        v-model="value.answer"
                        class="mb-2"
                        :options="snowOption"
                      />
                    </b-col>
                    <b-col
                      cols="1"
                      class="primary my-4"
                    >
                      <b-button
                        variant="primary"
                        class="btn-icon"
                        @click="categoryInformations.lg.questions.splice(keyBloc, 1);"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-col
                    span="12"
                    class="primary d-flex justify-content-end"
                  >
                    <b-button
                      variant="primary"
                      @click="addQuestion"
                    >
                      <span class="text-nowrap">Ajouter une question</span>
                    </b-button>
                  </b-col>
                </div>
              </b-col>
              <!-- <b-col
                cols="6"
                class="primary"
              >
                <b-img
                  fluid
                  :src="require(`@/assets/images/editor/seo.png`)"
                />
              </b-col> -->
            </b-row>
          </app-collapse-item>

          <app-collapse-item title="Section filtre">
            <b-row
              class="my-2"
            >
              <b-col
                cols="12"
                class="primary d-flex"
              >
                <div>
                  <p>Section:</p>
                  <b-row
                    v-for="(value, keyBloc) of categoryInformations.lg.filterSections"
                    :key="`${keyBloc}-${Math.floor(Math.random() * 1000)}-filter-section`"
                  >
                    <b-col
                      cols="3"
                      class="primary my-4"
                    >
                      <p>Image:</p>
                      <b-form-input
                        v-if="value"
                        v-model="value.img"
                        class="mr-4"
                        style="width: 100%"
                      />
                    </b-col>
                    <b-col
                      cols="3"
                      class="primary my-4"
                    >
                      <p>Title:</p>
                      <b-form-input
                        v-if="value"
                        v-model="value.title"
                        class="mr-4"
                        style="width: 100%"
                      />
                    </b-col>
                    <b-col
                      cols="4"
                      class="primary my-4"
                    >
                      <p>Link:</p>
                      <b-form-input
                        v-if="value"
                        v-model="value.link"
                        class="mr-4"
                        style="width: 100%"
                      />
                    </b-col>
                    <b-col
                      cols="1"
                      class="primary my-4"
                    >
                      <b-button
                        variant="primary"
                        class="btn-icon"
                        @click="categoryInformations.lg.filterSections.splice(keyBloc, 1);"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-col
                    span="12"
                    class="primary d-flex justify-content-end"
                  >
                    <b-button
                      variant="primary"
                      @click="addSection"
                    >
                      <span class="text-nowrap">Ajouter une section</span>
                    </b-button>
                  </b-col>
                </div>
              </b-col>
              <!-- <b-col
                cols="6"
                class="primary"
              >
                <b-img
                  fluid
                  :src="require(`@/assets/images/editor/seo.png`)"
                />
              </b-col> -->
            </b-row>
          </app-collapse-item>

          <app-collapse-item title="Instagram">
            <b-row
              class="my-2"
            >
              <b-col
                cols="12"
                class="primary d-flex"
              >
                <div>
                  <p>Posts:</p>
                  <b-row
                    v-for="(value, keyBloc) of categoryInformations.lg.instagramData"
                    :key="`${keyBloc}-influ`"
                  >
                    <b-col
                      cols="2"
                      class="primary my-4"
                    >
                      <p>Nom influenceuse (@exemple):</p>
                      <b-form-input
                        v-if="value"
                        v-model="value.name"
                        class="mr-4"
                        style="width: 100%"
                      />
                    </b-col>
                    <b-col
                      cols="2"
                      class="primary my-4"
                    >
                      <p>Link post Insta:</p>
                      <b-form-input
                        v-if="value"
                        v-model="value.post_link"
                        class="mr-4"
                        style="width: 100%"
                      />
                    </b-col>
                    <b-col
                      cols="2"
                      class="primary my-4"
                    >
                      <p>Lien image:</p>
                      <b-form-input
                        v-if="value"
                        v-model="value.link"
                        class="mr-4"
                        style="width: 100%"
                      />
                    </b-col>
                    <b-col
                      cols="1"
                      class="primary my-4"
                    >
                      <b-button
                        variant="primary"
                        class="btn-icon"
                        @click="categoryInformations.lg.instagramData.splice(keyBloc, 1);"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </b-col>
                    <b-col
                      cols="2"
                      class="primary my-4"
                    >
                      <img :src="value.link" style="width: 160px;"/>
                    </b-col>
                  </b-row>
                  <b-col
                    span="12"
                    class="primary d-flex justify-content-end"
                  >
                    <b-button
                      variant="primary"
                      @click="addPost"
                    >
                      <span class="text-nowrap">Ajouter un post</span>
                    </b-button>
                  </b-col>
                </div>
              </b-col>
              <!-- <b-col
                cols="6"
                class="primary"
              >
                <b-img
                  fluid
                  :src="require(`@/assets/images/editor/seo.png`)"
                />
              </b-col> -->
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="SEO">
            <b-row
              class="my-2"
            >
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Titre:</p>
                  <b-form-input
                    v-if="categoryInformations"
                    v-model="categoryInformations.lg.seo.title"
                    class="mr-4"
                    style="width: 100%"
                  />
                  <p>Image:</p>
                  <b-form-input
                    v-if="categoryInformations"
                    v-model="categoryInformations.lg.seo.image"
                    class="mr-4 mb-2"
                    style="width: 100%"
                  />
                  <p>Alt:</p>
                  <b-form-input
                    v-if="categoryInformations"
                    v-model="categoryInformations.lg.seo.alt"
                    class="mr-4 mb-2"
                    style="width: 100%"
                  />
                  <p>Blocs:</p>
                  <b-row
                    v-for="(value, keyBloc) of categoryInformations.lg.seo.blocs"
                    :key="keyBloc"
                  >
                    <b-col
                      cols="3"
                      class="primary my-4"
                    >
                      <p>Titre:</p>
                      <b-form-input
                        v-if="value"
                        v-model="value.title"
                        class="mr-4"
                        style="width: 100%"
                      />
                    </b-col>
                    <b-col
                      cols="6"
                      class="primary my-4"
                    >
                      <p>Texte:</p>
                      <quill-editor
                        v-if="value"
                        v-model="value.text"
                        class="mb-2"
                        :options="snowOption"
                      />
                    </b-col>
                    <b-col
                      cols="1"
                      class="primary my-4"
                    >
                      <b-button
                        variant="primary"
                        class="btn-icon"
                        @click="categoryInformations.lg.seo.blocs.splice(keyBloc, 1);"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-col
                    span="12"
                    class="primary d-flex justify-content-end"
                  >
                    <b-button
                      variant="primary"
                      @click="addBloc"
                    >
                      <span class="text-nowrap">Ajouter un bloc</span>
                    </b-button>
                  </b-col>
                </div>
              </b-col>
              <b-col
                cols="6"
                class="primary"
              >
                <b-img
                  fluid
                  :src="require(`@/assets/images/editor/seo.png`)"
                />
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Meta Description">
            <b-row
              class="my-2"
            >
              <b-col
                cols="12"
                class="primary d-flex"
              >
                <div
                  v-if="categoryInformations.lg.meta"
                  class="w-75"
                >
                  <p>Titre:</p>
                  <b-form-input
                    v-model="categoryInformations.lg.meta.title"
                    class="mb-2"
                    style="width: 100%"
                  />
                  <p>Meta description:</p>
                  <b-form-input
                    v-model="categoryInformations.lg.meta.description"
                    class="mb-2"
                    style="width: 100%"
                  />
                </div>
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Produits New Version">
            <b-row
              class="my-2"
            >
              <b-col
                cols="12"
                class="primary d-flex"
              >
                <p>Actif:</p>
                <v-select
                  v-model="categoryInformations.lg.isProductsV2"
                  placeholder="Produit"
                  label="name"
                  :options="[{name: 'Oui', value: true}, {name: 'Non', value: false }]"
                  :reduce="p => p.value"
                  class="invoice-filter-select"
                  transition=""
                />
              </b-col>
              <b-row
                v-for="(productV2, keyPr) of categoryInformations.lg.productsV2"
                :key="`${keyPr}-productv2`"
                cols="12"
                class="primary mt-2"
              >
                <b-col
                  cols="5"
                  class="primary d-flex"
                >
                  <div class="w-100 ml-2">
                    <p>Titre:</p>
                    <v-select
                      placeholder="Produit"
                      label="name"
                      :options="products"
                      class="invoice-filter-select"
                      transition=""
                      @input="e => setProductV2(e, keyPr, 'product')"
                    />
                  </div>
                </b-col>
                <b-col
                  cols="4"
                  class="w-100"
                >
                  <p>Titre:</p>
                  <b-form-input
                    v-if="productV2"
                    v-model="productV2.name"
                    class="mr-4"
                    style="width: 100%"
                    @input="e => setProductV2(e, keyPr, 'name')"
                  />
                </b-col>
                <b-col
                  cols="3"
                  class="w-100"
                >
                  <p>Lien image:</p>
                  <b-form-input
                    v-if="productV2"
                    v-model="productV2.img"
                    class="mr-4"
                    style="width: 100%"
                    @input="e => setProductV2(e, keyPr, 'img')"
                  />
                </b-col>
                <b-col
                  cols="2"
                  class="w-100 mt-2 ml-2"
                >
                  <p>Prix:</p>
                  <b-form-input
                    v-if="productV2"
                    v-model="productV2.price"
                    class="mr-4"
                    style="width: 100%"
                    @input="e => setProductV2(e, keyPr, 'price')"
                  />
                </b-col>
                <b-col
                  cols="2"
                  class="w-100 mt-2 ml-2"
                >
                  <p>Prix remisé:</p>
                  <b-form-input
                    v-if="productV2"
                    v-model="productV2.soldPrice"
                    class="mr-4"
                    style="width: 100%"
                    @input="e => setProductV2(e, keyPr, 'soldPrice')"
                  />
                </b-col>

                <b-col
                  cols="2"
                  class="w-100 mt-2 ml-2"
                >
                  <p>Specificité (carré vert):</p>
                  <b-form-input
                    v-if="productV2"
                    v-model="productV2.specificity"
                    class="mr-4"
                    style="width: 100%"
                    @input="e => setProductV2(e, keyPr, 'specificity')"
                  />
                </b-col>
                <b-col
                  cols="2"
                  class="w-100 mt-2 ml-2"
                >
                  <p>Lien:</p>
                  <b-form-input
                    v-if="productV2"
                    v-model="productV2.link"
                    class="mr-4"
                    style="width: 100%"
                    @input="e => setProductV2(e, keyPr, 'link')"
                  />
                </b-col>
                <b-col
                  cols="1"
                  class="primary my-4"
                >
                  <b-button
                    variant="primary"
                    class="btn-icon"
                    @click="categoryInformations.lg.productsV2.splice(keyPr, 1);"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </b-col>
              </b-row>
            </b-row>
            <b-col
              span="12"
              class="primary d-flex justify-content-end"
            >
              <b-button
                variant="primary"
                @click="addProductV2()"
              >
                <span class="text-nowrap">Ajouter un produit</span>
              </b-button>
            </b-col>
            <b-row>
              <p class="mr-2 ml-2">Position sur la page: </p>
              <draggable
                v-model="categoryInformations.lg.productsV2"
                @change="e => setProductV2(e, 0, 'dragg')"
              >
                <li
                  v-for="(product) in categoryInformations.lg.productsV2"
                  :key="product"
                  class="cursor-pointer"
                >
                  <span>{{ product.name }}</span>
                </li>
              </draggable>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Produits V2">
            <b-row
              class="my-2"
            >
              <b-col
                cols="12"
                class="primary d-flex"
              >
                <div
                  class="w-75"
                >
                  <v-select
                    v-model="categoryInformations.lg.products"
                    placeholder="Produit"
                    label="name"
                    :options="products"
                    :reduce="product => product.idWoo"
                    :multiple="true"
                    class="invoice-filter-select"
                    transition=""
                  />
                  <!-- <b-col
                    span="12"
                    class="primary d-flex justify-content-end"
                  >
                    <b-button
                      variant="primary"
                      @click="categoryInformations.lg.products.push({id: null, placement: -1})"
                    >
                      <span class="text-nowrap">Ajouter un bloc</span>
                    </b-button>
                  </b-col> -->
                </div>
              </b-col>
            </b-row>
            <b-row>
              <p class="mr-2 ml-2">Position sur la page: </p>
              <draggable
                v-model="categoryInformations.lg.products"
              >
                <li
                  v-for="(product, keyP) in categoryInformations.lg.products"
                  :key="product.id"
                  class="cursor-pointer"
                >
                  <span v-if="products && products.find(p => p.idWoo === product)">{{ `${keyP + 1}: ${products.find(p => p.idWoo === product).name}` }}</span>
                </li>
              </draggable>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Slug">
            <b-row
              class="my-2"
            >
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Slug :</p>
                  <b-form-input
                    v-model="categoryInformations.lg.slug"
                    class="mb-2"
                  />
                </div>
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Type">
            <b-row
              class="my-2"
            >
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Type de la catégorie :</p>
                  <v-select
                    v-model="categoryInformations.lg.type"
                    placeholder="Type"
                    label="name"
                    :options="['collection', 'standard', 'univers']"
                    class="invoice-filter-select"
                    transition=""
                  />
                </div>
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Catégorie Collection - Only">
            <b-row
              class="my-2"
            >
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Titre de la collection :</p>
                  <b-form-input
                    v-model="categoryInformations.lg.titleCollection"
                    class="mb-2"
                  />
                </div>
              </b-col>
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Banner de la catégorie (img) :</p>
                  <b-form-input
                    v-model="categoryInformations.lg.bannerCollection"
                    class="mb-2"
                  />
                </div>
              </b-col>
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Titre - Banner de la catégorie :</p>
                  <b-form-input
                    v-model="categoryInformations.lg.bannerTitleCollection"
                    class="mb-2"
                  />
                </div>
              </b-col>
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Sous-titre - Banner de la catégorie :</p>
                  <b-form-input
                    v-model="categoryInformations.lg.bannerSubTitleCollection"
                    class="mb-2"
                  />
                </div>
              </b-col>
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Description - Banner de la catégorie :</p>
                  <b-form-input
                    v-model="categoryInformations.lg.bannerDescriptionCollection"
                    class="mb-2"
                  />
                </div>
              </b-col>
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Description - Banner de la catégorie (mobile) :</p>
                  <b-form-input
                    v-model="categoryInformations.lg.bannerMobileDescriptionCollection"
                    class="mb-2"
                  />
                </div>
              </b-col>
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Image défilante de la catégorie (img):</p>
                  <b-form-input
                    v-model="categoryInformations.lg.imageCollection"
                    class="mb-2"
                  />
                </div>
              </b-col>
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Lien de l'image défilante de la catégorie (img):</p>
                  <b-form-input
                    v-model="categoryInformations.lg.linkCollection"
                    class="mb-2"
                  />
                </div>
              </b-col>
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Image défilante MOBILE de la catégorie (img):</p>
                  <b-form-input
                    v-model="categoryInformations.lg.imageMobileCollection"
                    class="mb-2"
                  />
                </div>
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Catégorie Collection - Sub-category">
            <b-row
              class="my-2"
            >
              <b-col
                cols="12"
                class="primary d-flex"
              >
                <div
                  class="w-75"
                >
                  <v-select
                    v-model="categoriesLink"
                    placeholder="Categorie"
                    label="title"
                    :options="categories"
                    :reduce="categorie => categorie._id"
                    :multiple="true"
                    class="invoice-filter-select"
                    transition=""
                  />
                </div>
              </b-col>
            </b-row>
            <b-row>
              <p class="mr-2 ml-2">Position sur la page: </p>
              <draggable
                v-model="categoriesLink"
              >
                <li
                  v-for="(category, keyP) in categoriesLink"
                  :key="category"
                  class="cursor-pointer"
                >
                  <span v-if="categories">{{ `${keyP + 1}: ${categories.find(c => c._id === category).title}` }}</span>
                </li>
              </draggable>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Catégorie Mère">
            <b-row
              class="my-2"
            >
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Nom de la catégorie :</p>
                  <b-form-input
                    v-model="categoryInformations.lg.motherCategory"
                    class="mb-2"
                  />
                </div>
              </b-col>
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Slug Catégorie :</p>
                  <b-form-input
                    v-model="categoryInformations.lg.motherCategorySlug"
                    class="mb-2"
                  />
                </div>
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Catégories Dérivées">
            <b-row
              class="my-2"
            >
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Titre:</p>
                  <b-form-input
                    v-model="categoryInformations.lg.categoriesLinkedTitle"
                    class="mb-2"
                  />
                </div>
              </b-col>
            </b-row>
            <b-row
              class="my-2"
            >
              <b-col
                cols="12"
                class="primary d-flex"
              >
                <div>
                  <p>Posts:</p>
                  <b-row
                    v-for="(value, keyCat) of categoryInformations.lg.categoriesLinked"
                    :key="`${keyCat}-categoryLinked`"
                  >
                    <b-col
                      cols="4"
                      class="primary my-4"
                    >
                      <p>Title:</p>
                      <b-form-input
                        v-if="value"
                        v-model="value.name"
                        class="mr-4"
                        style="width: 100%"
                      />
                    </b-col>
                    <b-col
                      cols="4"
                      class="primary my-4"
                    >
                      <p>Link:</p>
                      <b-form-input
                        v-if="value"
                        v-model="value.link"
                        class="mr-4"
                        style="width: 100%"
                      />
                    </b-col>
                    <b-col
                      cols="1"
                      class="primary my-4"
                    >
                      <b-button
                        variant="primary"
                        class="btn-icon"
                        @click="categoryInformations.lg.categoriesLinked.splice(keyCat, 1);"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-col
                    span="12"
                    class="primary d-flex justify-content-end"
                  >
                    <b-button
                      variant="primary"
                      @click="addLink"
                    >
                      <span class="text-nowrap">Ajouter un lien</span>
                    </b-button>
                  </b-col>
                </div>
              </b-col>
              <!-- <b-col
                cols="6"
                class="primary"
              >
                <b-img
                  fluid
                  :src="require(`@/assets/images/editor/seo.png`)"
                />
              </b-col> -->
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Carte Info">
            <b-row
              class="my-2"
            >
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Url de l'image:</p>
                  <b-form-input
                    v-model="categoryInformations.lg.imageCard"
                    class="mb-2"
                  />
                </div>
              </b-col>
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Lien :</p>
                  <b-form-input
                    v-model="categoryInformations.lg.linkCard"
                    class="mb-2"
                  />
                </div>
              </b-col>
              <b-col
                cols="3"
                class="primary d-flex"
              >
                <div>
                  <p>Emplacement n° ? :</p>
                  <b-form-input
                    v-model="categoryInformations.lg.placeCard"
                    class="mb-2"
                  />
                </div>
              </b-col>
            </b-row>
            <b-row
              class="my-2"
              v-for="(value, keyCard) of categoryInformations.lg.cards"
              :key="keyCard"
            >
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Url de l'image:</p>
                  <b-form-input
                    v-model="value.imageCard"
                    class="mb-2"
                  />
                </div>
              </b-col>
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Lien :</p>
                  <b-form-input
                    v-model="value.linkCard"
                    class="mb-2"
                  />
                </div>
              </b-col>
              <b-col
                cols="3"
                class="primary d-flex"
              >
                <div>
                  <p>Emplacement n° ? :</p>
                  <b-form-input
                    v-model="value.placeCard"
                    class="mb-2"
                  />
                </div>
              </b-col>
              <b-col
                cols="1"
                class="primary my-4"
              >
                <b-button
                  variant="primary"
                  class="btn-icon"
                  @click="categoryInformations.lg.cards.splice(keyCat, 1);"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                span="12"
                class="primary d-flex justify-content-end"
              >
                <b-button
                  variant="primary"
                  @click="addCard"
                >
                  <span class="text-nowrap">Ajouter une carte</span>
                </b-button>
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse>
        <b-row
          class="my-2"
        >
          <b-col
            span="6"
            class="primary d-flex justify-content-end"
          >
            <b-button
              variant="primary"
              :disabled="loading"
              @click="deleteCategory(categoryInformations)"
            >
              <span class="text-nowrap">Supprimer</span>
            </b-button>
          </b-col>
          <b-col
            span="6"
            class="primary d-flex justify-content-end"
          >
            <b-button
              variant="primary"
              :disabled="loading"
              @click="updateValue()"
            >
              <span class="text-nowrap">Modifier</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal
      v-model="showModalCreate"
      ok-only
      ok-title="Accept"
      centered
      size="lg"
      :hide-footer="true"
      lazy
      static
    >
      <b-row class="align-items-center mb-1">
        <b-col
          cols="6"
          class="primary"
        >
          <div>
            <p>Catégorie à dupliquer :</p>
            <v-select
              key="duplicate"
              v-model="categoryDuplicate"
              placeholder="Categorie"
              label="title"
              :options="categories"
              :get-option-label="option => `${option.country} - ${option.title}`"
              class="invoice-filter-select"
              transition=""
            />
          </div>
        </b-col>
        <b-col
          cols="6"
          class="primary"
        >
          <div>
            <p>Titre de la catégorie :</p>
            <b-form-input v-model="titleCategory" />
          </div>
        </b-col>
        <b-col
          cols="6"
          class="primary"
        >
          <div>
            <p>Slug de la catégorie :</p>
            <b-form-input v-model="slugCategory" />
          </div>
        </b-col>
      </b-row>
      <b-row class="align-items-center mb-1">
        <b-col
          cols="6"
          class="primary d-flex"
        >
          <b-button
            variant="primary"
            class="btn-cart mb-2"
            @click="createCategory"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
            />
            <span>Créer une catégorie</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-card title="Vous pouvez modifier les catégories directement ici.">
      <div>
        <b-button
          variant="primary"
          class="btn-cart mb-2"
          @click="showModalCreate = true"
        >
          <feather-icon
            icon="EditIcon"
            class="mr-50"
          />
          <span>Créer une catégorie</span>
        </b-button>
      </div>
      <b-row class="match-height">
        <b-col
          v-for="category in categories"
          :key="`${category._id}-${new Date()}`"
          md="3"
          lg="3"
          class=""
        >
          <b-card
            class="ecommerce-card"
            no-body
          >
            <div
              v-if="category.image"
              class="item-img text-center"
            >
              <b-img
                :alt="`${category.title}`"
                fluid
                class="card-img-top"
                style="height: 180px; object-fit: cover"
                :src="category.image"
              />
            </div>

            <!-- category Details -->
            <b-card-body class="text-center">
              <h4 class="item-name">
                {{ category.title }}
              </h4>
              <b-card-text class="item-description">
                {{ category.country }}
              </b-card-text>
            </b-card-body>

            <!-- Product Actions -->
            <div class="item-options text-center">
              <b-button
                variant="flat-primary"
                tag="a"
                class="btn-cart mb-1"
                @click="openModal(category)"
              >
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                />
                <span>Modifier la catégorie</span>
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BModal,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BButton,
  BFormInput,
  BCardText,
} from 'bootstrap-vue'
import QuillEditorSnow from '@core/components/quill-editor/QuillEditorCustom.vue'
import { Quill, quillEditor } from 'vue-quill-editor'
import draggable from 'vuedraggable'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BCardText,
    BModal,
    BRow,
    BCol,
    BImg,
    BButton,
    BCardBody,
    QuillEditorSnow,
    AppCollapse,
    AppCollapseItem,
    BFormInput,
    quillEditor,
    draggable,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,
    vSelect,
  },
  data() {
    return {
      loading: false,
      categories: [],
      categoriesLink: [],
      titleCategory: '',
      slugCategory: '',
      categoryDuplicate: null,
      categorie: {},
      showModal: false,
      categoryInformations: [],
      products: [],
      productsChoice: [],
      showModalCreate: false,
      newCategoryMapped: {},
      changePosition: false,
      snowOption: {
        theme: 'snow',
        modules: {
          toolbar: [['bold', 'italic', 'underline'], [{ list: 'ordered' }, { list: 'bullet' }], ['link']],
        },
      },
      whithoutOption: {
        theme: 'snow',
        modules: {
          toolbar: [],
        },
      },
    }
  },
  async mounted() {
    try {
      const Block = Quill.import('blots/block')
      Block.tagName = 'div'
      Quill.register(Block, true)
      const { categories } = await this.$http.get('/admin/categories')
      this.categories = categories
      const { products } = await this.$http.get('/admin/blog/list-product/?country=FR')
      this.products = products
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    addBloc() {
      this.categoryInformations.lg.seo.blocs.push({ title: '', text: '' })
    },
    addQuestion() {
      this.categoryInformations.lg.questions.push({ question: '', answer: '' })
      this.$forceUpdate()
    },
    addSection() {
      this.categoryInformations.lg.filterSections.push({ img: '', title: '', link: '' })
      this.$forceUpdate()
    },
    addPost() {
      this.categoryInformations.lg.instagramData.push({
        post_link: '',
        name: '',
        link: '',
      })
    },
    addLink() {
      this.categoryInformations.lg.categoriesLinked.push({
        link: '',
        name: '',
      })
      this.$forceUpdate()
    },
    addCard() {
      this.categoryInformations.lg.cards.push({
        imageCard: '',
        linkCard: '',
        placeCard: '',
      })
      this.$forceUpdate()
    },
    addProductV2() {
      this.categoryInformations.lg.productsV2.push(
        {
          id: null, name: '', img: '', price: '', link: '',
        },
      )
      this.$forceUpdate()
    },
    setProductV2(product, keyP, keyProduct) {
      if (['name', 'img', 'price', 'link', 'specificity', 'soldPrice'].includes(keyProduct)) {
        this.categoryInformations.lg.productsV2[keyP][keyProduct] = product
      } else if (keyProduct === 'dragg') {
        console.log('fd')
      } else {
        this.categoryInformations.lg.productsV2[keyP].id = product.id
        this.categoryInformations.lg.productsV2[keyP].name = product.name
      }
      this.$forceUpdate()
    },
    async openModal(categorie) {
      try {
        const { products } = await this.$http.get(`/admin/blog/list-product/?country=${categorie.country}`)
        this.products = products
        this.showModal = true
        this.categorie = categorie
        this.categoryInformations = categorie.data
        this.categoriesLink = categorie._categoriesLink || []
        if (!this.categoryInformations.lg.productsV2) {
          this.categoryInformations.lg.productsV2 = []
        }
        if (!this.categoryInformations?.lg?.seo) {
          this.categoryInformations.lg.seo = {
            title: '',
            image: '',
            alt: '',
            blocs: [{ title: '', text: '' }],
          }
        }
        if (!this.categoryInformations.lg.cards) {
          this.categoryInformations.lg.cards = []
        }
        if (!this.categoryInformations?.lg?.meta) {
          this.categoryInformations.lg.meta = {
            title: '',
            description: '',
          }
        }
        if (!this.categoryInformations?.lg?.univers) {
          this.categoryInformations.lg.univers = {
            title: '',
            subTitle: '',
            description: '',
            image: '',
          }
        }
        if (!this.categoryInformations?.lg?.instagramData) {
          this.categoryInformations.lg.instagramData = [{
            post_link: '',
            name: '',
            link: '',
          }]
        }

        if (!this.categoryInformations?.lg?.categoriesLinked) {
          this.categoryInformations.lg.categoriesLinked = []
        }
        if (!this.categoryInformations?.lg?.filterSections) {
          this.categoryInformations.lg.filterSections = [{
            img: '', title: '', link: '',
          }]
        }
        if (!this.categoryInformations?.lg?.questions) {
          this.categoryInformations.lg.questions = [{ question: '', answer: '' }]
        }
      } catch (err) {
        console.log(err)
      }
    },
    convertHtml(html) {
      // eslint-disable-next-line newline-per-chained-call
      return html?.replaceAll('<span style="color: rgb(0, 0, 0);">', '')?.replaceAll('</span>', '')?.replaceAll('style="color: rgb(0, 0, 0);"', '')
    },
    async updateValue() {
      try {
        this.loading = true
        this.categoryInformations.lg.reinsurance.subtitle = this.convertHtml(this.categoryInformations.lg.reinsurance.subtitle)
        this.categoryInformations.lg.reinsurance.values = this.categoryInformations.lg.reinsurance.values?.map(v => ({ ...v, description: this.convertHtml(v.description) }))
        this.categoryInformations.lg.services.subtitle = this.convertHtml(this.categoryInformations.lg.services.subtitle)
        this.categoryInformations.lg.explication.description = this.convertHtml(this.categoryInformations.lg.explication.description)
        this.categoryInformations.lg.seo.blocs = this.categoryInformations.lg.seo.blocs?.map(v => ({ ...v, text: this.convertHtml(v.text) }))
        this.categoryInformations._categoriesLink = this.categoriesLink
        await this.$http.put(`admin/categories/${this.categorie._id}`, { categoryInformations: this.categoryInformations })
        const { categories } = await this.$http.get('/admin/categories')
        this.categories = categories
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Enregistrement réussi',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    async createCategory() {
      try {
        const categoryMapped = { ...this.categoryDuplicate, slug: this.slugCategory, title: this.titleCategory }
        await this.$http.post('/admin/categories', { ...categoryMapped })
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Votre catégorie a bien été créée',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.showModalCreate = false
      } catch (err) {
        console.log(err)
      }
    },
    async deleteCategory() {
      try {
        await this.$http.delete(`admin/categories/${this.categorie._id}`)
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Votre catégorie a bien été supprimé.',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.showModal = false
        const { categories } = await this.$http.get('/admin/categories')
        this.categories = categories
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>

<style>
 .jsoneditor-poweredBy {
   display: none;
 }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
